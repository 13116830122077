import $ from "jquery";
import 'lightbox2';
import '../../global/js/cms.js';
var Cookies = require('js-cookie')

$(function() {
    Background.init();
    Buildings.init();
});

var Buildings = {
    node: $('#buildingsList'),
    selected: 'all',
    init: function() {
        var self = this;
        this.node.find('a').each(function() {
            var x = $(this).attr('data-x') + 'px';
            var y = $(this).attr('data-y') + 'px';
            $(this).css('top', y).css('left', x);
        }).click(function(e) {
            e.preventDefault();

            var item = $(this);
            self.activateItem(item);

            return false;
        });
    },
    activateItem: function(item) {

        var pageContent = $('.page-content');
        if(item.attr('data-active') == 'all' || this.selected == item.attr('data-active')) {
            this.node.find('a').css('opacity', 1);
            pageContent.find('h3').css('display', 'block');
            pageContent.find('table.buildings').css('display', 'block');
            this.selected = 'all';
        } else {
            this.node.find('a').css('opacity', 0);
            item.css('opacity', 1);
            pageContent.find('h3:not(.neverHide)').css('display', 'none');
            pageContent.find('table.buildings').each(function () {
                if ($(this).attr('id') == item.attr('data-active')) {
                    console.log($(this).attr('id'));
                    $(this).css('display', 'block').prevAll("h3:first").css('display', 'block');
                } else {
                    $(this).css('display', 'none');
                }
            });
            this.selected = item.attr('data-active');
        }
    }
};

var Background = {
    current: 2,
    init: function() {
        if(Cookies.get('background') != undefined) {
            this.current = parseInt(Cookies.get('background'));
        }
        $('body').css('background-image', "url('../img/gradient.png'), url('../img/background" + Background.current + ".jpg')");

        $('#creature').click(function() {
            if(Background.current == 3) {
                Background.current = 1;
            } else {
                Background.current += 1;
            }
            Cookies.set('background', Background.current, { expires: 180 });
            $('body').css('background-image', "url('../img/gradient.png'), url('../img/background" + Background.current + ".jpg')");
        });

    }
};
